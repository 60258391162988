import React from "react";
import CategorySection from "./categorySection";
import ProductSuggetions from "../product/productSuggest";
import CategorySuggetions from "../product/categorySuggest";
import SerachBox from "./searchbox";
import { Helmet } from "react-helmet";


const Home = ({ user, isproPage }) => {

    return (
        <>
            <Helmet>
                <title>Best Deals on Agricultural Products - Karnataka Agribusiness Opportunities</title>
                <meta name="description" content="Discover Karnataka’s agribusiness potential. Explore deals on organic farming, cutting-edge agri-tech, and sustainable farming products." />
                <meta name="keywords" content="Agribusiness Karnataka, Organic farming, Agricultural exports" />
            </Helmet>


            {/* Hero Section */}
            <section>
                <img
                    src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1733122833/KAB-home_ujjunj.png"
                    alt="home.pic"
                    className="object-cover"
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "100%"
                    }}
                />
            </section>

            <SerachBox />

            {/* Category Sections */}
            <div>
                <CategorySection />
            </div>

            {/* Product You May Like Sections */}
            <div>
                <ProductSuggetions user={user} isproPage={isproPage} />
            </div>

            {/* Categories You May Like Sections */}
            <div>
                <CategorySuggetions />
            </div>
        </>
    );
};

export default Home;
